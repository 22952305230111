import { validBondouxStoreNames } from '../utils/constant';

let appNavigation: null | HTMLElement = null;

export const initializeAppBridge = async () =>
  new Promise<void>((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://cdn.shopify.com/shopifycloud/app-bridge.js';
    scriptTag.async = false;
    scriptTag.defer = false;
    scriptTag.addEventListener('load', () => {
      resolve();
      console.log('Loaded');
    });
    scriptTag.addEventListener('error', err => {
      reject(err);
      console.log('Errored');
    });
    window.document.head.prepend(scriptTag);
  });

export function createAppNavigation(isSetupCompleted = false) {
  if (!isSetupCompleted) return;
  if (appNavigation) {
    appNavigation.remove();
    appNavigation = null;
  }

  appNavigation = document.createElement('ui-nav-menu');
  appNavigation.innerHTML = `
      <a href="/" rel="home">Home</a>
      <a href="/invoices">Report</a>
      ${
        validBondouxStoreNames.includes(window.shopName)
          ? `<a href="/fulfillments">Fulfillments</a>`
          : ''
      }
      <a href="/settings">Settings</a>
      <a href="/pricing">Pricing</a>
      <a href="/support">Support</a>
  `;
  document.body.append(appNavigation);
}
