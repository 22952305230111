import { useEffect, useState } from 'react';
import { Button } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

export default function GoBackOrToAppBtn({
  goBackUrlInAdmin,
}: {
  goBackUrlInAdmin: string;
}) {
  const [isTimeoutCompleted, setIsTimeoutCompleted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.history.pushState(null, '', `shopify://admin/${goBackUrlInAdmin}`);
      setIsTimeoutCompleted(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [goBackUrlInAdmin, navigate]);

  if (isTimeoutCompleted) {
    return (
      <Button
        loading={false}
        onClick={() => {
          navigate('/');
        }}
        variant='primary'
      >
        Continue to app
      </Button>
    );
  }

  return <Button disabled>Going Back in 5 seconds</Button>;
}
