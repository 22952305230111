import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Button } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';
import GoBackOrToAppBtn from '../components/GoBackOrToAppBtn';

function SendInvoiceToCustomerForDraftOrder() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [, setRes] = useState(null);
  const [err, setErr] = useState<null | AxiosError<{ message: string }>>(null);
  const [draftOrderId] = useState<string>(
    () => new URLSearchParams(window.location.search).get('id')!,
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const shop = searchParams.get('shop');

    httpInterceptor
      .get('/api/order/draft/send', {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          id: draftOrderId,
          shop,
        },
      })
      .then(res => {
        setRes(res.data);
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [draftOrderId]);

  if (loading) return <CommonScreen loading />;

  if (err && (!err.response || err.response.status !== 400)) {
    shopify.toast.show('Something went wrong', {
      duration: 5000,
    });
    return <CommonScreen loading={false} />;
  }

  if (err) {
    shopify.toast.show(err!.response!.data.message, {
      duration: 5000,
    });

    return (
      <CommonScreen loading={false}>
        <Button
          onClick={() => {
            navigate('/pricing');
          }}
          variant='primary'
        >
          Upgrade to paid plan
        </Button>
      </CommonScreen>
    );
  }

  shopify.toast.show('Mail sent successfully!', {
    duration: 5000,
  });

  return (
    <CommonScreen loading={false}>
      <GoBackOrToAppBtn goBackUrlInAdmin={`/draft_orders/${draftOrderId}`} />
    </CommonScreen>
  );
}

export default SendInvoiceToCustomerForDraftOrder;
