import { BlockStack, Button, Image } from '@shopify/polaris';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CommonScreen({
  loading,
  children,
}: PropsWithChildren<{
  loading: boolean;
}>) {
  const navigate = useNavigate();
  return (
    <BlockStack inlineAlign='center' align='center' gap='500'>
      <Image
        width={80}
        height={80}
        source='/images/app-icon.png'
        alt='Invoice Hero'
      />
      {children || (
        <Button
          loading={loading}
          onClick={() => {
            navigate('/');
          }}
          variant='primary'
        >
          Continue to app
        </Button>
      )}
    </BlockStack>
  );
}
