/* eslint-disable @typescript-eslint/no-loss-of-precision */
import axios, { type AxiosResponse } from 'axios';
import type {
  GlobalPlan,
  LanguageData,
  allPlan,
  FetchInvoiceQueryParams,
} from '../types/api.types';

import { apiEndpointWithTrailingSlash } from '../utils/constant';
import { FetchInvoicesRes } from '../types/api.types';
// import getAppBridgeInstance from './app-bridge.service';

const axiosInstance = axios.create({
  headers: {
    frontend: '1',
  },
  baseURL: apiEndpointWithTrailingSlash,
});

axiosInstance.interceptors.request.use(async config => {
  if (config.headers.get('un-authenticated') === 'true') {
    config.headers.delete('un-authenticated');
    return config;
  }

  config.headers.set('Authorization', `Bearer ${await shopify.idToken()}`);
  return config;
});

export const httpInterceptor = axiosInstance;

const addOrderDataToSettingsRes = (res: AxiosResponse): void => {
  const tempOrder: Record<string, any> = {
    id: 820982911946154508,
    email: 'jon@doe.ca',
    closed_at: null,
    created_at: '2020-05-21T05:16:23-04:00',
    updated_at: '2020-05-21T05:16:23-04:00',
    number: 234,
    note: null,
    token: '123456abcd',
    gateway: null,
    test: true,
    total_price: '254.98',
    subtotal_price: '244.98',
    total_weight: 0,
    total_tax: '0.00',
    taxes_included: false,
    currency: 'USD',
    financial_status: 'voided',
    confirmed: false,
    total_discounts: '5.00',
    total_line_items_price: '249.98',
    cart_token: null,
    buyer_accepts_marketing: true,
    name: '#0001',
    referring_site: null,
    landing_site: null,
    cancelled_at: null,
    cancel_reason: null,
    checkout_token: null,
    reference: null,
    user_id: null,
    location_id: null,
    source_identifier: null,
    source_url: null,
    processed_at: null,
    device_id: null,
    phone: null,
    customer_locale: 'en',
    app_id: null,
    browser_ip: null,
    landing_site_ref: null,
    order_number: 1234,
    discount_applications: [
      {
        type: 'manual',
        value: '5.0',
        value_type: 'fixed_amount',
        allocation_method: 'one',
        target_selection: 'explicit',
        target_type: 'line_item',
        description: 'Discount',
        title: 'Discount',
      },
    ],
    discount_codes: [],
    note_attributes: [],
    payment_gateway_names: ['visa', 'bogus'],
    processing_method: '',
    checkout_id: null,
    source_name: 'web',
    fulfillment_status: 'pending',
    tax_lines: [],
    tags: '',
    contact_email: 'jon@doe.ca',
    order_status_url:
      'https://rajeshboppana1.myshopify.com/8770060365/orders/123456abcd/authenticate?key=abcdefg',
    presentment_currency: 'USD',
    total_line_items_price_set: {
      shop_money: {
        amount: '249.98',
        currency_code: 'USD',
      },
      presentment_money: {
        amount: '249.98',
        currency_code: 'USD',
      },
    },
    total_discounts_set: {
      shop_money: {
        amount: '5.00',
        currency_code: 'USD',
      },
      presentment_money: {
        amount: '5.00',
        currency_code: 'USD',
      },
    },
    total_shipping_price_set: {
      shop_money: {
        amount: '10.00',
        currency_code: 'USD',
      },
      presentment_money: {
        amount: '10.00',
        currency_code: 'USD',
      },
    },
    subtotal_price_set: {
      shop_money: {
        amount: '244.98',
        currency_code: 'USD',
      },
      presentment_money: {
        amount: '244.98',
        currency_code: 'USD',
      },
    },
    total_price_set: {
      shop_money: {
        amount: '254.98',
        currency_code: 'USD',
      },
      presentment_money: {
        amount: '254.98',
        currency_code: 'USD',
      },
    },
    total_tax_set: {
      shop_money: {
        amount: '0.00',
        currency_code: 'USD',
      },
      presentment_money: {
        amount: '0.00',
        currency_code: 'USD',
      },
    },
    line_items: [
      {
        id: 487817672276298554,
        variant_id: null,
        title: 'Aviator sunglasses',
        quantity: 1,
        sku: 'SKU2006-001',
        variant_title: 'Black, Full Rim, 137 mm',
        vendor: null,
        product_id: 788032119674292922,
        requires_shipping: true,
        taxable: true,
        gift_card: false,
        name: 'Aviator sunglasses',
        variant_inventory_management: null,
        properties: [],
        product_exists: true,
        fulfillable_quantity: 1,
        grams: 100,
        price: '89.99',
        total_discount: '0.00',
        fulfillment_status: null,
        price_set: {
          shop_money: {
            amount: '89.99',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '89.99',
            currency_code: 'USD',
          },
        },
        total_discount_set: {
          shop_money: {
            amount: '0.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '0.00',
            currency_code: 'USD',
          },
        },
        discount_allocations: [],
        duties: [],
        admin_graphql_api_id: 'gid://shopify/LineItem/487817672276298554',
        tax_lines: [],
      },
      {
        id: 976318377106520349,
        variant_id: null,
        title: 'Mid-century lounger',
        quantity: 1,
        sku: 'SKU2006-020',
        variant_title: 'Cream White, Walnut Wood',
        vendor: null,
        product_id: 788032119674292922,
        requires_shipping: true,
        taxable: true,
        gift_card: false,
        name: 'Mid-century lounger',
        variant_inventory_management: null,
        properties: [],
        product_exists: true,
        fulfillable_quantity: 1,
        grams: 1000,
        price: '159.99',
        total_discount: '5.00',
        fulfillment_status: null,
        price_set: {
          shop_money: {
            amount: '159.99',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '159.99',
            currency_code: 'USD',
          },
        },
        total_discount_set: {
          shop_money: {
            amount: '5.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '5.00',
            currency_code: 'USD',
          },
        },
        discount_allocations: [
          {
            amount: '5.00',
            discount_application_index: 0,
            amount_set: {
              shop_money: {
                amount: '5.00',
                currency_code: 'USD',
              },
              presentment_money: {
                amount: '5.00',
                currency_code: 'USD',
              },
            },
          },
        ],
        duties: [],
        admin_graphql_api_id: 'gid://shopify/LineItem/976318377106520349',
        tax_lines: [],
      },
    ],
    fulfillments: [],
    refunds: [],
    total_tip_received: '0.0',
    original_total_duties_set: null,
    current_total_duties_set: null,
    admin_graphql_api_id: 'gid://shopify/Order/820982911946154508',
    shipping_lines: [
      {
        id: 271878346596884015,
        title: 'Generic Shipping',
        price: '10.00',
        code: null,
        source: 'shopify',
        phone: null,
        requested_fulfillment_service_id: null,
        delivery_category: null,
        carrier_identifier: null,
        discounted_price: '10.00',
        price_set: {
          shop_money: {
            amount: '10.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '10.00',
            currency_code: 'USD',
          },
        },
        discounted_price_set: {
          shop_money: {
            amount: '10.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '10.00',
            currency_code: 'USD',
          },
        },
        discount_allocations: [],
        tax_lines: [],
      },
    ],
    billing_address: {
      first_name: 'Bob',
      address1: '123 Billing Street',
      phone: '555-555-BILL',
      city: 'Billtown',
      zip: 'K2P0B0',
      province: 'Kentucky',
      country: 'United States',
      last_name: 'Biller',
      address2: null,
      company: 'My Company',
      latitude: null,
      longitude: null,
      name: 'Bob Biller',
      country_code: 'US',
      province_code: 'KY',
    },
    shipping_address: {
      first_name: 'Steve',
      address1: '123 Shipping Street',
      phone: '555-555-SHIP',
      city: 'Shippington',
      zip: '40003',
      province: 'Kentucky',
      country: 'United States',
      last_name: 'Shipper',
      address2: null,
      company: 'Shipping Company',
      latitude: null,
      longitude: null,
      name: 'Steve Shipper',
      country_code: 'US',
      province_code: 'KY',
    },
    customer: {
      id: 115310627314723954,
      email: 'john@test.com',
      accepts_marketing: false,
      created_at: null,
      updated_at: null,
      first_name: 'John',
      last_name: 'Smith',
      state: 'disabled',
      note: null,
      verified_email: true,
      multipass_identifier: null,
      tax_exempt: false,
      phone: null,
      tags: '',
      currency: 'USD',
      accepts_marketing_updated_at: null,
      marketing_opt_in_level: null,
      admin_graphql_api_id: 'gid://shopify/Customer/115310627314723954',
      default_address: {
        id: 715243470612851245,
        customer_id: 115310627314723954,
        first_name: null,
        last_name: null,
        company: null,
        address1: '123 Elm St.',
        address2: null,
        city: 'Ottawa',
        province: 'Ontario',
        country: 'Canada',
        zip: 'K2H7A8',
        phone: '123-123-1234',
        name: '',
        province_code: 'ON',
        country_code: 'CA',
        country_name: 'Canada',
        default: true,
      },
    },
    lineItemsGroupedByProduct: [
      {
        productTitle: 'IPod Nano - 8gb',
        sku: 'IPOD2008GREEN',
        totalQuantity: 10,
        price_set: {
          shop_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
        },
        quantity: 1,
        discount_allocations: [],
        totalAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        unitAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        variants: [
          {
            title: 'm01',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm02',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm03',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm04',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm05',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm06',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm07',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm08',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm09',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm10',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm11',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm12',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm13',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm14',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm15',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
        ],
      },
      {
        productTitle: 'IPod Nano - 8gb',
        sku: 'IPOD2008GREEN',
        totalQuantity: 10,
        price_set: {
          shop_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
        },
        quantity: 1,
        discount_allocations: [],
        totalAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        unitAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        variants: [
          {
            title: 'm01',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm02',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm03',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm04',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm05',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm06',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm07',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm08',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm09',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm10',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm11',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm12',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm13',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm14',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm15',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
        ],
      },
      {
        productTitle: 'IPod Nano - 8gb',
        sku: 'IPOD2008GREEN',
        totalQuantity: 10,
        price_set: {
          shop_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
        },
        quantity: 1,
        discount_allocations: [],
        totalAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        unitAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        variants: [
          {
            title: 'm01',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm02',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm03',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm04',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm05',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm06',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm07',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm08',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm09',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm10',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm11',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm12',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm13',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm14',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm15',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
        ],
      },
      {
        productTitle: 'IPod Nano - 8gb',
        sku: 'IPOD2008GREEN',
        totalQuantity: 10,
        price_set: {
          shop_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
        },
        quantity: 1,
        discount_allocations: [],
        totalAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        unitAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        variants: [
          {
            title: 'm01',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm02',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm03',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm04',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm05',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm06',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm07',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm08',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm09',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm10',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm11',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm12',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm13',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm14',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm15',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
        ],
      },
      {
        productTitle: 'IPod Nano - 8gb',
        sku: 'IPOD2008GREEN',
        totalQuantity: 10,
        price_set: {
          shop_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
        },
        quantity: 1,
        discount_allocations: [],
        totalAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        unitAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        variants: [
          {
            title: 'm01',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm02',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm03',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm04',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm05',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm06',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm07',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm08',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm09',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm10',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm11',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm12',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm13',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm14',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm15',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
        ],
      },
      {
        productTitle: 'IPod Nano - 8gb',
        sku: 'IPOD2008GREEN',
        totalQuantity: 10,
        price_set: {
          shop_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '199.00',
            currency_code: 'USD',
          },
        },
        quantity: 1,
        discount_allocations: [],
        totalAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        unitAmount: {
          shop_money: {
            amount: '4000',
            currency_code: 'USD',
          },
          presentment_money: {
            amount: '4000',
            currency_code: 'USD',
          },
        },
        variants: [
          {
            title: 'm01',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm02',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm03',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm04',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm05',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm06',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm07',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm08',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm09',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm10',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm11',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm12',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm13',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm14',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
          {
            title: 'm15',
            quantity: '5',
            sku: 'IPOD2008BLACK',
          },
        ],
      },
    ],
  };
  tempOrder.note = 'This invoice is for your P.O. 4356 ';
  tempOrder.total_refunded_amount = {
    shop_money: {
      amount: '10.00',
      currency_code: 'INR',
    },
    presentment_money: {
      amount: '10.00',
      currency_code: 'INR',
    },
  };

  tempOrder.total_tip_received = {
    shop_money: {
      amount: '0.00',
      currency_code: res.data.storeCurrency,
    },
    presentment_money: {
      amount: '0.00',
      currency_code: res.data.storeCurrency,
    },
  };
  tempOrder.netPaymentSet = {
    shop_money: {
      amount: '0.00',
      amountNumber: 0,
      currency_code: res.data.storeCurrency,
    },
    presentment_money: {
      amount: '0.00',
      amountNumber: 0,
      currency_code: res.data.storeCurrency,
    },
  };
  tempOrder.outstandingSet = {
    shop_money: {
      amount: '254.98',
      amountNumber: 254.98,
      currency_code: res.data.storeCurrency,
    },
    presentment_money: {
      amount: '254.98',
      amountNumber: 254.98,
      currency_code: res.data.storeCurrency,
    },
  };
  tempOrder.refunds = [
    {
      id: 509562969,
      order_id: 450789469,
      created_at: '2019-07-04T15:12:40-04:00',
      note: 'it broke during shipping',
      user_id: 799407056,
      processed_at: '2019-07-04T15:12:40-04:00',
      restock: true,
      admin_graphql_api_id: 'gid://shopify/Refund/509562969',
      refund_line_items: [
        {
          id: 104689539,
          quantity: 1,
          line_item_id: 703073504,
          location_id: 487838322,
          restock_type: 'legacy_restock',
          subtotal: 199.0,
          total_tax: 3.98,
          subtotal_set: {
            shop_money: {
              amount: '199.00',
              currency_code: 'USD',
            },
            presentment_money: {
              amount: '199.00',
              currency_code: 'USD',
            },
          },
          total_tax_set: {
            shop_money: {
              amount: '3.98',
              currency_code: 'USD',
            },
            presentment_money: {
              amount: '3.98',
              currency_code: 'USD',
            },
          },
          line_item: {
            id: 703073504,
            variant_id: 457924702,
            title: 'IPod Nano - 8gb',
            quantity: 1,
            sku: 'IPOD2008BLACK',
            variant_title: 'black',
            vendor: null,
            product_id: 632910392,
            requires_shipping: true,
            taxable: true,
            gift_card: false,
            name: 'IPod Nano - 8gb - black',
            variant_inventory_management: 'shopify',
            properties: [],
            product_exists: true,
            fulfillable_quantity: 1,
            grams: 200,
            price: '199.00',
            total_discount: '0.00',
            fulfillment_status: null,
            price_set: {
              shop_money: {
                amount: '199.00',
                currency_code: 'USD',
              },
              presentment_money: {
                amount: '199.00',
                currency_code: 'USD',
              },
            },
            total_discount_set: {
              shop_money: {
                amount: '0.00',
                currency_code: 'USD',
              },
              presentment_money: {
                amount: '0.00',
                currency_code: 'USD',
              },
            },
            discount_allocations: [],
            admin_graphql_api_id: 'gid://shopify/LineItem/703073504',
            tax_lines: [
              {
                title: 'State Tax',
                price: '3.98',
                rate: 0.06,
                price_set: {
                  shop_money: {
                    amount: '3.98',
                    currency_code: 'USD',
                  },
                  presentment_money: {
                    amount: '3.98',
                    currency_code: 'USD',
                  },
                },
              },
            ],
          },
        },
        {
          id: 709875399,
          quantity: 1,
          line_item_id: 466157049,
          location_id: 487838322,
          restock_type: 'legacy_restock',
          subtotal: 199.0,
          total_tax: 3.98,
          subtotal_set: {
            shop_money: {
              amount: '199.00',
              currency_code: 'USD',
            },
            presentment_money: {
              amount: '199.00',
              currency_code: 'USD',
            },
          },
          total_tax_set: {
            shop_money: {
              amount: '3.98',
              currency_code: 'USD',
            },
            presentment_money: {
              amount: '3.98',
              currency_code: 'USD',
            },
          },
          line_item: {
            id: 466157049,
            variant_id: 39072856,
            title: 'IPod Nano - 8gb',
            quantity: 2,
            sku: 'IPOD2008GREEN',
            variant_title: 'green',
            vendor: null,
            product_id: 632910392,
            requires_shipping: true,
            taxable: true,
            gift_card: false,
            name: 'IPod Nano - 8gb - green',
            variant_inventory_management: 'shopify',
            properties: [
              {
                name: 'Custom Engraving Front',
                value: 'Happy Birthday',
              },
              {
                name: 'Custom Engraving Back',
                value: 'Merry Christmas',
              },
            ],
            product_exists: true,
            fulfillable_quantity: 1,
            grams: 200,
            price: '199.00',
            total_discount: '0.00',
            fulfillment_status: null,
            price_set: {
              shop_money: {
                amount: '199.00',
                currency_code: 'USD',
              },
              presentment_money: {
                amount: '199.00',
                currency_code: 'USD',
              },
            },
            total_discount_set: {
              shop_money: {
                amount: '0.00',
                currency_code: 'USD',
              },
              presentment_money: {
                amount: '0.00',
                currency_code: 'USD',
              },
            },
            discount_allocations: [],
            admin_graphql_api_id: 'gid://shopify/LineItem/466157049',
            tax_lines: [
              {
                title: 'State Tax',
                price: '3.98',
                rate: 0.06,
                price_set: {
                  shop_money: {
                    amount: '3.98',
                    currency_code: 'USD',
                  },
                  presentment_money: {
                    amount: '3.98',
                    currency_code: 'USD',
                  },
                },
              },
            ],
          },
        },
      ],
      transactions: [
        {
          id: 179259969,
          order_id: 450789469,
          kind: 'refund',
          gateway: 'bogus',
          status: 'success',
          message: null,
          created_at: '2005-08-05T12:59:12-04:00',
          test: false,
          authorization: 'authorization-key',

          location_id: null,

          user_id: null,

          parent_id: 801038806,

          processed_at: '2005-08-05T12:59:12-04:00',

          device_id: null,

          receipt: {},

          error_code: null,

          source_name: 'web',

          amount: '10.00',

          currency: 'USD',

          admin_graphql_api_id: 'gid://shopify/OrderTransaction/179259969',
        },
      ],

      order_adjustments: [],
    },
  ];

  tempOrder.transactions = [
    {
      id: 179259969,

      order_id: 450789469,

      kind: 'refund',

      gateway: 'bogus',

      status: 'success',

      message: null,

      created_at: '2005-08-05T12:59:12-04:00',

      test: false,

      authorization: 'authorization-key',

      location_id: null,

      user_id: null,

      parent_id: 801038806,

      processed_at: '2005-08-05T12:59:12-04:00',

      device_id: null,

      receipt: {},

      error_code: null,

      source_name: 'web',

      amount: '10.00',

      currency: 'INR',

      admin_graphql_api_id: 'gid://shopify/OrderTransaction/179259969',

      payment_details: {
        credit_card_bin: null,

        avs_result_code: null,

        cvv_result_code: null,

        credit_card_number: '•••• •••• •••• 4242',

        credit_card_company: 'Visa',
      },
    },
  ];

  tempOrder.netPaymentSet = {
    presentment_money: {
      amount: '0.00',

      currency_code: res.data.storeCurrency,
    },

    shop_money: {
      amount: '0.00',

      currency_code: res.data.storeCurrency,
    },
  };

  tempOrder.paidByCustomerSet = {
    presentment_money: {
      amount: '10.00',

      currency_code: res.data.storeCurrency,
    },

    shop_money: {
      amount: '10.00',

      currency_code: res.data.storeCurrency,
    },
  };

  tempOrder.gateway = 'Bank transfer';

  tempOrder.payment_details = {
    credit_card_bin: null,

    avs_result_code: null,

    cvv_result_code: null,

    credit_card_number: '•••• •••• •••• 4242',

    credit_card_company: 'Visa',
  };

  tempOrder.invoicehero_vatNumber = 'GB123456789';

  for (let i = 0; i < tempOrder.line_items.length; i += 1) {
    if (i === 0) {
      tempOrder.line_items[i].barcode = '12345 00010';

      tempOrder.line_items[i].countryOfOrigin = 'US';

      tempOrder.line_items[i].hsCode = '900410';

      tempOrder.line_items[i].productWeight = '10 g';

      tempOrder.line_items[i].productImg =
        'https://mlveda-shopifyapps.s3.amazonaws.com/invoice-hero/sunglasses.jpg';
    } else {
      tempOrder.line_items[i].barcode = '12345 00011';

      tempOrder.line_items[i].countryOfOrigin = 'US';

      tempOrder.line_items[i].hsCode = '900412';

      tempOrder.line_items[i].productWeight = '20 g';

      tempOrder.line_items[i].productImg =
        'https://mlveda-shopifyapps.s3.amazonaws.com/invoice-hero/noImage.png';
    }
  }

  res.data.orders = tempOrder;
};

export const fetchSettings = async () => {
  const res = await axiosInstance.get('/api/settings');
  addOrderDataToSettingsRes(res);

  return res.data;
};

export const fetchShopData = async () => {
  const res = await axiosInstance.get('/api/shop');
  return res.data;
};

export const fetchEmailSettings = async () => {
  const res = await axiosInstance.get('/api/emailSettings');
  return { ...res.data, additionalPDF: res.data.additionalPDF ?? [] };
};

// use: Onboarding step 2
export const fetchLanguageData = async () => {
  const res = await axiosInstance.get('/api/language');
  return res.data as LanguageData[];
};

export const fetchTemplateData = async () => {
  const res = await axiosInstance.get('/api/template');
  return res.data;
};

export const fetchFontFamiliesData = async () => {
  const res = await axiosInstance.get('/api/fontFamilies');
  return res.data;
};

export const fetchInvoices = async (
  params: FetchInvoiceQueryParams,
  signal: AbortSignal | undefined,
): Promise<FetchInvoicesRes> => {
  const res = await axiosInstance.get<FetchInvoicesRes>('/api/order', {
    signal,
    params: {
      ...params,
      startDate: params.startDate?.toUTCString(),
      endDate: params.endDate?.toUTCString(),
    },
  });
  return res.data;
};

export const fetchFulfillments = async (params: {
  limit: number;
  searchText: string;
  pageNo: number;
}) => {
  const res = await axiosInstance.get('/api/fulfillments', {
    params,
  });
  return res.data;
};
export const fetchOrderFulfillments = async (orderId: string) => {
  const res = await axiosInstance.get(`/api/order/${orderId}/fulfillments`);
  return res.data;
};
// use: Onboarding step 2, step 3, pricingPage, pricingOfferPage
export const updateSettingsApiCall = async (settingsData: object) => {
  const res = await axiosInstance.put('/api/settings', settingsData);
  addOrderDataToSettingsRes(res);
  return res.data;
};

export const toggleSendInvoiceAutomaticApiCall = async (
  settingsData: any,
  checkFlag: boolean,
) => {
  const { firstTimeSendInvoiceAutomaticEvent } = settingsData.invoiceSettings;

  return updateSettingsApiCall(
    checkFlag!
      ? {
          invoiceSettings: {
            sendInvoiceAutomatic: true,
            orderCreateSendInvoiceAutomatic:
              firstTimeSendInvoiceAutomaticEvent === 'CREATED',
            orderRefundSendInvoiceAutomatic: true,
            orderCancelSendInvoiceAutomatic: true,
            orderEditSendInvoiceAutomatic: true,
            orderAddressChangeSendInvoiceAutomatic: false,
            orderEmailChangeSendInvoiceAutomatic: false,
          },
        }
      : {
          invoiceSettings: {
            sendInvoiceAutomatic: false,
            orderCreateSendInvoiceAutomatic: false,
            orderRefundSendInvoiceAutomatic: false,
            orderCancelSendInvoiceAutomatic: false,
            orderEditSendInvoiceAutomatic: false,
            orderAddressChangeSendInvoiceAutomatic: false,
            orderEmailChangeSendInvoiceAutomatic: false,
          },
        },
  );
};

export const fetchAllPlans = async () => {
  const res = await axiosInstance.get('/plan');
  return res.data as allPlan[];
};

// use: pricingOfferPage
export const fetchGlobalPlans = async () => {
  const res = await axiosInstance.get('/plan/global');
  return res.data as GlobalPlan[];
};

// post call use: pricingOfferPage
export const addPlanDeactivateMutation = async (data: any) => {
  const res = await axiosInstance.post('plan/deactivate', data);
  return res.data;
};

export const fetchDefaultStringData = async (languageCode: any) => {
  const res = await axiosInstance.get(
    `/api/settings/defaultInvoiceMultiLangkeys/${languageCode}`,
  );
  return res.data;
};

// post call use : onboarding step2
export const updateLanguageChangeApiCall = async (language: object) => {
  const res = await axiosInstance.post(
    '/api/settings/changelanguage',
    language,
  );
  return res.data;
};

// post call use: pricingOfferPage
export const updatePlanDiscountApiCall = async (discountState: object) => {
  const res = await axiosInstance.post('plan/discount', discountState);
  return res.data;
};

// use: onboarding step3
export const updateEmailSettingsApiCall = async (emailSettingsData: any) => {
  const res = await axiosInstance.put('/api/emailSettings', emailSettingsData);
  return res.data;
};

// post add call use : pricingOfferPage, pricingPage
export const updatePlanUpgradedApiCall = async (
  planId: string,
  // data is object
  data: {
    isPlanUpgraded: boolean;
  },
) => {
  const res = await axiosInstance.post(`plan/${planId}`, data);
  return res.data;
};

// use: pricing customPricing
export const fetchCustomPricingPlans = async () => {
  const res = await axiosInstance.get('api/custom-price');
  return res.data;
};
export const generatePDFApiCall = async (reqData: object) => {
  const res = await axiosInstance.post('/api/order/generatePDF', reqData);
  return res.data;
};
export const generateFulfillmentPDFApiCall = async ({
  fulfillmentId,
  orderId,
}: {
  orderId: number;
  fulfillmentId: number;
}) => {
  const res = await axiosInstance.post(
    `/api/order/${orderId}/fulfillments/${fulfillmentId}/regenerate`,
  );
  return res.data;
};
export const sendEmailApiCall = async (reqData: object) => {
  const res = await axiosInstance.post('/api/order/sendEmail', reqData);
  return res.data;
};

export const sendFulfillmentEmailApiCall = async (reqData: any) => {
  const res = await axiosInstance.post('/api/order/sendEmail', reqData, {
    params: {
      fulfillmentId: reqData.fulfillmentId,
    },
  });
  return res.data;
};
export const generatePDFAndSendEmailApiCall = async (reqData: object) => {
  const res = await axiosInstance.post(
    '/api/order/generatePDFAndSendEmail',
    reqData,
  );
  return res.data;
};

export const sendMergedPDFsToOwnerApiCall = async (ordersData: object) => {
  const res = await axiosInstance.post('/api/order/bulk/print', ordersData);
  return res.data;
};

export const sendPDFsToCustomersApiCall = async (ordersData: object) => {
  const res = await axiosInstance.post('/api/order/bulk/send', ordersData);
  return res.data;
};

export const sendPDFsToOwnerApiCall = async (ordersData: object) => {
  const res = await axiosInstance.post('/api/order/bulk', ordersData);
  return res.data;
};
export const fetchAnalytics = async () => {
  const res = await axiosInstance.get<{
    totalInvoicesSentLastMonth: number;
    totalInvoicesSentThisMonth: number;
    totalInvoicesGeneratedThisMonth: number;
    totalInvoicesGeneratedLastMonth: number;
  }>('/api/order/analytics');
  return res.data;
};

export const fetchOrderTemplateAndData = async (orderId: string) => {
  const res = await axiosInstance.post<{
    bodyHTML: string;
    headerHTML: string;
    footerHTML: string;
    fullHTML: string;
    customOrderNumber: string;
  }>(`/api/order/generateHTML`, {
    orderId: Number.parseInt(orderId, 10),
  });
  return res.data;
};

export const generatePdfFromHtml = async (data: {
  headerHTML: string;
  bodyHTML: string;
  footerHTML: string;
}) => {
  const domParser = new DOMParser();
  const getFullyFormedHTML = (html: string) => {
    const doc = domParser.parseFromString(html, 'text/html');
    return `
      <!DOCTYPE html>
      <html>
        <head>${doc.head.innerHTML}</head>
        <body>${doc.body.innerHTML}</body>
      </html>
    `;
  };
  const res = await axiosInstance.post<File>(
    `/api/order/generatePdfFromHtml`,
    {
      bodyHTML: data.bodyHTML,
      headerHTML: getFullyFormedHTML(data.headerHTML),
      footerHTML: getFullyFormedHTML(data.footerHTML),
    },
    {
      responseType: 'blob',
    },
  );
  return res.data;
};
