import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';
import GoBackOrToAppBtn from '../components/GoBackOrToAppBtn';

function SendInvoiceToCustomerForOrder() {
  const [loading, setLoading] = useState(true);
  const [, setRes] = useState(null);
  const [err, setErr] = useState<null | AxiosError>(null);
  const [orderId] = useState<string>(
    new URLSearchParams(window.location.search).get('id')!,
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const shop = searchParams.get('shop');
    const isSendMail = searchParams.get('isSendMail');

    httpInterceptor
      .get('/api/order/invoice', {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          id: orderId,
          shop,
          isSendMail,
        },
      })
      .then(res => {
        setRes(res.data);
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orderId]);

  if (loading) return <h1>Loading...</h1>;

  if (err && (!err.response || err.response.status !== 400)) {
    shopify.toast.show('Something went wrong', {
      duration: 5000,
      isError: true,
    });
    return <CommonScreen loading />;
  }

  if (
    err &&
    err.response &&
    err.response.status === 400 &&
    err.response.data &&
    (err.response.data as any).code === 'PLAN_LIMIT_EXCEEDED'
  ) {
    shopify.toast.show((err.response.data as any).message, {
      duration: 5000,
      isError: true,
    });
    return <CommonScreen loading />;
  }

  if (err) {
    shopify.toast.show(
      "We are unable to send the invoice for your order. Please make sure the customer's email id is not empty in the order details.",
      {
        duration: 5000,
        isError: true,
      },
    );
    return <CommonScreen loading={false} />;
  }

  shopify.toast.show('Successfully sent mail to the customer!', {
    duration: 5000,
  });

  return (
    <CommonScreen loading={false}>
      <GoBackOrToAppBtn goBackUrlInAdmin={`/orders/${orderId}`} />
    </CommonScreen>
  );
}

export default SendInvoiceToCustomerForOrder;
